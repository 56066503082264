import { formatDate } from "@/utils/date";
const Base64 = require("js-base64").Base64;
// import md5 from "js-md5";
import store from "@/store";

/**
 * 生成接口访问签名
 */
export function formatSysSign(value) {
  // console.log(store.state.user.sys_info);
  var str = store.state.app.sys_info;
  var host = "www.hksqzysg.com";
  if (process.env.NODE_ENV == "production") {
    host = window.location.host;
  }
  return md5(str[1] + value + str[0] + host);
}

/**
 * 判断系统
 */
export function systemAgent() {
  const userAgent = navigator.userAgent;
  if (/(Windows|Macintosh)/.test(userAgent)) {
    return 1;
  } else if (/Android/.test(userAgent)) {
    return 2;
  } else if (/iPhone|iPad|iPod/.test(userAgent)) {
    return 2;
  } else {
    return 1;
  }
}

/**
 * custom base64
 * 解密
 */
export function decryptCustomBase64(cusotmStr, start_site, str_length) {
  //1、掐头去尾
  var strArr = store.state.app.sys_info;
  start_site = start_site === undefined ? strArr[2] : start_site;
  str_length = str_length === undefined ? strArr[3] : str_length;
  start_site = start_site * 1;
  str_length = str_length * 1;
  cusotmStr = cusotmStr.substring(0, cusotmStr.length - str_length);
  cusotmStr = cusotmStr.substring(str_length, cusotmStr.length);
  //2、去中间

  var str = cusotmStr.substring(start_site, start_site + str_length);
  return Base64.decode(cusotmStr.split(str).join(""));
}

/**
 * custom base64
 * 加密
 */
export function ecryptCustomBase64(str, start_site, str_length) {
  var strArr = store.state.app.sys_info;
  var enMethod = strArr[5];
  console.log("加密方式**********：" + enMethod);
  console.log("steArr*********:" + strArr);
  console.log("str***********:" + str);
  start_site = start_site === undefined ? strArr[2] : start_site;
  str_length = str_length === undefined ? strArr[3] : str_length;
  start_site = start_site * 1;
  str_length = str_length * 1;
  var base64 = Base64.encode(str);
  base64 =
    base64.slice(0, start_site) +
    randomString(str_length) +
    base64.slice(start_site);
  return randomString(str_length) + base64 + randomString(str_length);
}

function randomString(len, charSet) {
  charSet =
    charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var randomString = "";
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length);
    randomString += charSet.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
}

/*
 * @Author: Jordan
 * @Date: 2019-07-24 19:19:07
 * @Last Modified by: Jordan
 * @Last Modified time: 2020-09-23 16:50:13
 */

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function formatTimeDate(value, type) {
  if (value) {
    return formatDate(new Date(value), type);
  }
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

/**
 * @param {string} value
 * @returns {Boolean}
 */
export function checkPhone(value) {
  if (
    !/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/.test(value)
  ) {
    return false;
  }
}

/**
 * 手机号码正则验证
 * @param {String} value
 */
export function checkMobile(value) {
  if (!/^1[3456789]\d{9}$/.test(value)) {
    return false;
  } else {
    return true;
  }
}

/**
 * base64转二进制
 * @param {String} base64Str
 */
export function convertBase64UrlToBytes(base64Str) {
  var bytes = window.atob(base64Str.split(",")[1]);
  var ia = new Array(bytes.length);
  for (var i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return ia;
}

/**
 * 判断是Android终端
 * @returns {Boolean}
 */
export function isAndroid() {
  const ua = navigator.userAgent.toLowerCase();
  // Android终端
  const isAndroid = ua.indexOf("Android") > -1 || ua.indexOf("Adr") > -1;
  if (isAndroid) {
    return true;
  } else {
    return false;
  }
}
/**
 * 判断是IOS终端
 * @returns {Boolean}
 */
export function isIOS() {
  const ua = navigator.userAgent;
  // Ios终端
  const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isiOS) {
    return true;
  } else {
    return false;
  }
}

/**
 * 身份证号数字替换成*
 * @param {String}
 */
export function idNumToStar(value) {
  var str = String(value);
  var reg = str.slice(-13, -4);
  return str.replace(reg, "****");
}
/*
 * 身份证正则验证
 * @param {String} value
 */
// 验证身份证
export function checkID(num) {
  num = num.toUpperCase();
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
  if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
    // alert('输入的身份证号长度不对，或者号码不符合规定！\n15位号码应全为数字，18位号码末位可以为数字或X。');
    return false;
  }
  // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
  // 下面分别分析出生日期和校验位
  var len, re;
  len = num.length;
  if (len === 15) {
    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
    var arrSplit = num.match(re);

    // 检查生日日期是否正确
    var dtmBirth = new Date(
      "19" + arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]
    );
    var bGoodDay;
    bGoodDay =
      dtmBirth.getYear() === Number(arrSplit[2]) &&
      dtmBirth.getMonth() + 1 === Number(arrSplit[3]) &&
      dtmBirth.getDate() === Number(arrSplit[4]);
    if (!bGoodDay) {
      // alert('输入的身份证号里出生日期不对！');
      return false;
    } else {
      // 将15位身份证转成18位
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      var arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      var arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
      var nTemp = 0;
      var i;
      num = num.substr(0, 6) + "19" + num.substr(6, num.length - 6);
      for (i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i];
      }
      num += arrCh[nTemp % 11];
      return true;
    }
  }
  if (len === 18) {
    // (\d{7})
    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
    arrSplit = num.match(re);

    // 检查生日日期是否正确
    dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
    bGoodDay =
      dtmBirth.getFullYear() === Number(arrSplit[2]) &&
      dtmBirth.getMonth() + 1 === Number(arrSplit[3]) &&
      dtmBirth.getDate() === Number(arrSplit[4]);
    if (!bGoodDay) {
      // alert('输入的身份证号里出生日期不对！');
      return false;
    } else {
      // 检验18位身份证的校验码是否正确。
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      var valnum;
      arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      arrCh = ["1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"];
      nTemp = 0;
      for (i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i];
      }
      valnum = arrCh[nTemp % 11];
      if (valnum !== num.substr(17, 1)) {
        // alert('18位身份证的校验码不正确！应该为：' + valnum);
        return false;
      }
      return true;
    }
  }
  return false;
}

export function checkIdentify(type, identityCard) {
  type = type * 1;
  if (type === 0) {
    return checkID(identityCard);
  } else if (type === 1) {
    var re = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/;
    return re.test(identityCard);
  } else if (type === 2) {
    var rel1 = /^[0-9]{8}$/;
    var rel2 = /^[0-9]{10}$/;
    return rel1.test(identityCard) || rel2.test(identityCard);
  } else if (type === 3) {
    var re1 = /^[a-zA-Z]{5,17}$/;
    var re2 = /^[a-zA-Z0-9]{5,17}$/;
    return re2.test(identityCard) || re1.test(identityCard);
  }
}

/**
 * 根据身份证获取年龄
 * @param {*} identityCard
 */
export function GetAge(identityCard) {
  var len = (identityCard + "").length;
  if (len === 0) {
    return 0;
  } else {
    if (len !== 15 && len !== 18) {
      return 0;
    }
  }
  var strBirthday = "";
  if (len === 18) {
    strBirthday =
      identityCard.substr(6, 4) +
      "/" +
      identityCard.substr(10, 2) +
      "/" +
      identityCard.substr(12, 2);
  }
  if (len === 15) {
    strBirthday =
      "19" +
      identityCard.substr(6, 2) +
      "/" +
      identityCard.substr(8, 2) +
      "/" +
      identityCard.substr(10, 2);
  }
  // 时间字符串里,必須是“/”
  var birthDate = new Date(strBirthday);
  var nowDateTime = new Date();
  var age = nowDateTime.getFullYear() - birthDate.getFullYear();
  // 再考虑月、天的因素;.getMonth()获取的是从0开始的,这里进行比较,不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() === birthDate.getMonth() &&
      nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
}
/**
 * 根据出生日期获取年龄
 * @param {*} str
 */
export function getBirthAge(str, startTime) {
  if (str) {
    var timeStamp = new Date(
      str.substring(0, 4) +
        "-" +
        str.substring(4, 6) +
        "-" +
        str.substring(6, 8)
    );
    var now;
    if (startTime) {
      now = new Date(startTime);
    } else {
      now = new Date();
    }
    var nowYear = now.getFullYear();
    var trueYear = timeStamp.getFullYear();
    var age = nowYear - trueYear;
    if (now.getMonth() < timeStamp.getMonth()) {
      age--;
    } else {
      if (
        now.getMonth() === timeStamp.getMonth() &&
        now.getDate() < timeStamp.getDate()
      ) {
        age--;
      }
    }
    return age;
  }
}

/*
 * 判断是否是IE 11及以下或者其他(其他里包括IE edge)
 */
export function isIE() {
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    return true;
  } else {
    return false;
  }
}
/*
 * 过滤video标签的下载按钮
 */
export function videoNoDownLoad(value) {
  if (value) {
    value.replace(
      /<(video).*?(\/>|<\/video>)/g,
      '<video controlsList="nodownload"'
    );
    return value;
  }
}

// 判断IE浏览器版本
export function IEVersion() {
  var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
  var isIE =
    userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; // 判断是否IE<11浏览器
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; // 判断是否IE的Edge浏览器
  var isIE11 =
    userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1;
  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    reIE.test(userAgent);
    var fIEVersion = parseFloat(RegExp["$1"]);
    if (fIEVersion === 7) {
      return 7;
    } else if (fIEVersion === 8) {
      return 8;
    } else if (fIEVersion === 9) {
      return 9;
    } else if (fIEVersion === 10) {
      return 10;
    } else {
      return 6; // IE版本<=7
    }
  } else if (isEdge) {
    return "edge"; // edge
  } else if (isIE11) {
    return 11; // IE11
  } else {
    return -1; // 不是ie浏览器
  }
}
