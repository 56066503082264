<template>
  <div class="header">
    <div class="box" v-if="$store.state.note.userAgent == 1">
      <div class="logo" @click="goHome()">
        <img src="@/assets/img/logo.png" alt="" />
      </div>
      <div class="nav">
        <div class="nav_item" @click="goNavr(2)">
          <div class="icon" v-if="$store.state.note.homeNav == 2">
            <img src="@/assets/icon/train_e.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/train.png" alt="" />
          </div>
          <div
            class="txt"
            :style="$store.state.note.homeNav == 2 ? 'color:#FFD300;' : ''"
          >
            路線駅
          </div>
        </div>
        <div class="nav_item" @click="goNavr(3)">
          <div class="icon" v-if="$store.state.note.homeNav == 3">
            <img src="@/assets/icon/pin_drop_e.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/pin_drop.png" alt="" />
          </div>
          <div
            class="txt"
            :style="$store.state.note.homeNav == 3 ? 'color:#FFD300;' : ''"
          >
            住所
          </div>
        </div>
        <div class="nav_item" @click="goNavr(1)">
          <div class="icon" v-if="$store.state.note.homeNav == 1">
            <img src="@/assets/icon/map_e.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/map.png" alt="" />
          </div>
          <div
            class="txt"
            :style="$store.state.note.homeNav == 1 ? 'color:#FFD300;' : ''"
          >
            エリア
          </div>
        </div>
        <div class="nav_item" @click="goNavr(4)">
          <div
            class="icon"
            v-if="
              $store.state.note.homeNav == 4 || $store.state.note.homeNav == 5
            "
          >
            <img src="@/assets/icon/groups_e.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/groups.png" alt="" />
          </div>
          <div
            class="txt"
            :style="
              $store.state.note.homeNav == 4 || $store.state.note.homeNav == 5
                ? 'color:#FFD300;'
                : ''
            "
          >
            面積・人数
          </div>
        </div>
        <div class="nav_item" @click="goSupport()">
          <div class="icon" v-if="$store.state.note.homeNav == 9">
            <img src="@/assets/icon/support_agent_e.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/support_agent.png" alt="" />
          </div>
          <div
            class="txt"
            :style="$store.state.note.homeNav == 9 ? 'color:#FFD300;' : ''"
          >
            スタッフに相談
          </div>
        </div>
        <div class="nav_item" @click="goNum(6)">
          <div class="icon" v-if="$store.state.note.homeNav == 6">
            <img src="@/assets/icon/pin_e.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/pin.png" alt="" />
          </div>
          <div
            class="txt"
            :style="$store.state.note.homeNav == 6 ? 'color:#FFD300;' : ''"
          >
            物件No.
          </div>
        </div>
        <div class="nav_item" @click="goHistory(7)">
          <div class="icon" v-if="$store.state.note.homeNav == 7">
            <img src="@/assets/icon/history.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/history.png" alt="" />
          </div>
          <div
            class="txt"
            :style="$store.state.note.homeNav == 7 ? 'color:#FFD300;' : ''"
          >
            閲覧履歴
          </div>
        </div>
        <div class="nav_item" @click="goStar(8)">
          <div class="icon" v-if="$store.state.note.homeNav == 8">
            <img src="@/assets/icon/start_w.png" alt="" />
          </div>
          <div class="icon" v-else>
            <img src="@/assets/icon/star_rate.png" alt="" />
          </div>
          <div
            class="txt"
            :style="$store.state.note.homeNav == 8 ? 'color:#FFD300;' : ''"
          >
            お気に入り
          </div>
        </div>
      </div>
    </div>
    <div class="box_phone" v-else>
      <div class="logo" @click="goHomeSp()">
        <img src="@/assets/img/logo.png" alt="" />
      </div>
      <div class="box_nav">
        <div class="nav_item" @click="goStarSp()">
          <div class="item_icon">
            <img src="@/assets/imgs/sp_menu02.png" alt="" />
          </div>
          <div class="item_txt">お気に入り</div>
        </div>
        <div
          class="nav_item"
          @click="centerDialogVisible = !centerDialogVisible"
        >
          <div class="item_icon" v-if="centerDialogVisible">
            <img src="@/assets/imgs/sp_menu01.png" alt="" />
          </div>
          <div class="item_icon" v-else>
            <img src="@/assets/icon/sp_menu01.png" alt="" />
          </div>
          <div class="item_txt">メニュー</div>
        </div>
      </div>
      <el-collapse-transition>
        <div class="nav_lists_box" v-if="centerDialogVisible">
          <div class="nav_lists_item" @click="goNavrsp('mone', 1)">
            <template>
              <div
                class="nav_list_icon"
                v-if="$store.state.note.homeNavSp == 1"
              >
                <img src="@/assets/menu/map.png" alt="" />
              </div>
              <div class="nav_list_icon" v-else>
                <img src="@/assets/menu/map_e.png" alt="" />
              </div>
            </template>
            <div
              class="nav_list_txt"
              :style="$store.state.note.homeNavSp == 1 ? 'color:#FFD300;' : ''"
            >
              エリアから探す
            </div>
          </div>
          <div class="nav_lists_item" @click="goNavrsp('mtwo', 2)">
            <template>
              <div
                class="nav_list_icon"
                v-if="$store.state.note.homeNavSp == 2"
              >
                <img src="@/assets/menu/train.png" alt="" />
              </div>
              <div class="nav_list_icon" v-else>
                <img src="@/assets/menu/train_e.png" alt="" />
              </div>
            </template>

            <div
              class="nav_list_txt"
              :style="$store.state.note.homeNavSp == 2 ? 'color:#FFD300;' : ''"
            >
              路線駅から探す
            </div>
          </div>
          <div
            class="nav_lists_item"
            style="padding-right: 16px"
            @click="goNavrsp('mthree', 3)"
          >
            <template>
              <div
                class="nav_list_icon"
                v-if="$store.state.note.homeNavSp == 3"
              >
                <img src="@/assets/menu/pin_drop.png" alt="" />
              </div>
              <div class="nav_list_icon" v-else>
                <img src="@/assets/menu/pin_drop_e.png" alt="" />
              </div>
            </template>

            <div
              class="nav_list_txt"
              :style="$store.state.note.homeNavSp == 3 ? 'color:#FFD300;' : ''"
            >
              住所から探す
            </div>
          </div>
          <div
            class="nav_lists_item"
            style="padding-right: 10px"
            @click="goNavrsp('mfour', 4)"
          >
            <template>
              <div
                class="nav_list_icon"
                v-if="$store.state.note.homeNavSp == 4"
              >
                <img src="@/assets/menu/dashboard_customize_.png" alt="" />
              </div>
              <div class="nav_list_icon" v-else>
                <img src="@/assets/menu/dashboard_customize_e.png" alt="" />
              </div>
            </template>

            <div
              class="nav_list_txt"
              :style="$store.state.note.homeNavSp == 4 ? 'color:#FFD300;' : ''"
            >
              面積から探す
            </div>
          </div>
          <div
            class="nav_lists_item"
            style="padding-left: 24px"
            @click="goNavrsp('mfive', 5)"
          >
            <template>
              <div
                class="nav_list_icon"
                v-if="$store.state.note.homeNavSp == 5"
              >
                <img src="@/assets/menu/groups.png" alt="" />
              </div>
              <div class="nav_list_icon" v-else>
                <img src="@/assets/menu/groups_e.png" alt="" />
              </div>
            </template>

            <div
              class="nav_list_txt"
              :style="$store.state.note.homeNavSp == 5 ? 'color:#FFD300;' : ''"
            >
              使用人数から探す
            </div>
          </div>
          <!-- <div class="nav_lists_item" @click="goSupportSp()"> -->
          <div
            class="nav_lists_item"
            style="padding-left: 10px"
            @click="goNavrsp('mconsult', 6)"
          >
            <template>
              <div
                class="nav_list_icon"
                v-if="$store.state.note.homeNavSp == 6"
              >
                <img src="@/assets/icon/support_agent_e.png" alt="" />
              </div>
              <div class="nav_list_icon" v-else>
                <img src="@/assets/icon/support_agent.png" alt="" />
              </div>
            </template>

            <div
              class="nav_list_txt"
              :style="$store.state.note.homeNavSp == 6 ? 'color:#FFD300;' : ''"
            >
              スタッフに相談
            </div>
          </div>
          <!-- <div class="nav_lists_item" @click="goHistorym()"> -->
          <div
            class="nav_lists_item"
            style="padding-right: 30px"
            @click="goNavrsp('mhistory', 7)"
          >
            <template>
              <div
                class="nav_list_icon"
                v-if="$store.state.note.homeNavSp == 7"
              >
                <img src="@/assets/icon/history.png" alt="" />
              </div>
              <div class="nav_list_icon" v-else>
                <img src="@/assets/icon/history.png" alt="" />
              </div>
            </template>

            <div
              class="nav_list_txt"
              :style="$store.state.note.homeNavSp == 7 ? 'color:#FFD300;' : ''"
            >
              閲覧履歴
            </div>
          </div>
          <div class="nav_list_search">
            <div class="ter_item_icon">
              <img src="@/assets/menu/pin.png" alt="" />
            </div>
            <div class="ter_item_txt">
              物件No.
              <b class="sm">で個別検索する</b>
            </div>
            <div class="list_item_input">
              <el-input v-model="search" placeholder="物件No.を入力">
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  @click="goSearchNo()"
                ></i>
              </el-input>
            </div>
          </div>
          <div class="nav_list_search">
            <div class="ter_item_icon">
              <img src="@/assets/imgs/search.png" alt="" />
            </div>
            <div class="ter_item_txt">
              フリーワード
              <b class="sm">で検索する</b>
            </div>
            <div class="list_item_input">
              <div class="search_sele">
                <el-select class="to_ads" v-model="searchType">
                  <el-option
                    v-for="item in searchTypelist"
                    :key="item.id"
                    :label="item.lable"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <el-input
                class="tesw"
                v-model="searchb"
                placeholder="物件名・住所名・駅名を入力"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                  @click="goSearchse()"
                ></i>
              </el-input>
            </div>
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <div class="disload_box" v-if="disloagOve_6">
      <div class="boxsiz">
        <div class="close_btn" @touchstart="handleClose_6">
          <i class="el-icon-close"></i>
        </div>
        <div class="content_header"></div>
        <div class="content_header_to">ご希望の物件種目を選択してください</div>
        <div class="radio_box">
          <el-checkbox-group
            v-model="radioHustype"
            @change="handleRadioHustype"
            style="width: 100px; padding: 20px 0"
          >
            <el-checkbox class="radiohome" :label="1">事務所</el-checkbox>
            <el-checkbox class="radiohome" :label="3"
              >事務所 兼 店舗</el-checkbox
            >
            <el-checkbox class="radiohome" :label="2">店舗</el-checkbox>
            <el-checkbox class="radiohome" :label="4"
              >SOHO（住宅兼事務所）</el-checkbox
            >
            <el-checkbox class="radiohome" :label="7">住宅</el-checkbox>
            <el-checkbox class="radiohome" :label="5">倉庫</el-checkbox>
            <el-checkbox class="radiohome" :label="6">駐車場</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="content_footer">
          <div class="content_footer_btn" @touchstart="disasdGo()">
            この条件で検索する
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      centerDialogVisible: false,
      search: "",
      searchb: "",
      homeNav: 1,
      searchType: 1,
      disloagOve_6: false,
      radioHustype: [],
      searchTypelist: [
        { id: 1, lable: "物件名" },
        { id: 3, lable: "住所名" },
        { id: 2, lable: "駅名" },
      ],
    };
  },
  mounted() {
    console.log("header");
  },
  methods: {
    goNavr(event) {
      this.$store.commit("SET_HOMENAV", event);
      this.$router.push({ name: "index" });
    },
    handleClose_6() {
      this.disloagOve_6 = false;
    },
    handleRadioHustype(e) {
      if (e.length) {
        if (e.at(-1) != 6 && e.at(-1) != 7) {
          this.radioHustype = e.filter((val) => val != 6 && val != 7);
        } else {
          this.radioHustype = [e.at(-1)];
        }
      } else {
        this.radioHustype = [];
      }
    },
    goNavrsp(event, num) {
      this.centerDialogVisible = false;
      if (num != 6 && num != 7) {
        localStorage.removeItem("searchParamsData");
        localStorage.removeItem("searchSectionType");
        localStorage.removeItem("searchAdataSp");
        localStorage.removeItem("searchBdataSp");
        localStorage.removeItem("searchCdataSp");
        localStorage.removeItem("searchDdataSp");
        localStorage.removeItem("searchEdataSp");
        localStorage.removeItem("searchValue");
      }
      this.$store.commit("SET_HOMENAV_SP", num);
      this.$router.push({ name: event });
    },
    goSupport() {
      // this.$router.push({ name: 'inApply' })
      this.$store.commit("SET_HOMENAV", 9);
      this.$router.push({ name: "consult" });
    },
    goNum() {
      this.$store.commit("SET_HOMENAV", 6);
      this.$router.push({ name: "index" });
      // this.$router.push({ name: 'consult' })
    },
    goSupportSp() {
      // this.$router.push({ name: 'inApply' })
      this.centerDialogVisible = false;
      this.$router.push({ name: "mconsult" });
    },
    goSearchse() {
      if (!this.searchb) {
        this.$message.error("フリーワードを入力してください");
        return;
      }
      this.$router.push( {name: "mobileindex"})
      let params = {
        keyType: this.searchType,
        keyWords: this.searchb,
      };
      localStorage.setItem("searchValue", JSON.stringify(params));
      this.$apis.postProperty(params).then((res) => {
        if (res.data.code == 200) {
          this.centerDialogVisible = false;
          if (res.data.data.params.showList) {
            if (res.data.data.result.length == 1) {
              let _ew = res.data.data.result[0];
              if (_ew.officeData.length == 1) {
                this.$router.push({
                  name: "mdetail",
                  query: {
                    propertyId: _ew.officeData[0].property_id,
                    purposeKbn: _ew.officeData[0].purpose_kbn,
                    id: _ew.officeData[0].id,
                    hus_type: _ew.officeData[0].hus_type,
                  },
                });
              } else {
                this.$router.push({
                  name: "msearchlist",
                  params: {
                    keyWords: this.searchb,
                    sectionType: [_ew.officeData[0].purpose_kbn],
                  },
                });
              }
            } else {
              // console.log(this.disloagOve_6)
              this.disloagOve_6 = true;
              // let _ewq = res.data.data.result[0]
            }
          } else {
            if (res.data.data.propertyData) {
              let _data = res.data.data;
              localStorage.setItem("errorData", JSON.stringify(_data));
              this.$router.push("/medetail");
            } else {
              this.$router.push("/meseacher");
            }
          }
        } else {
          this.centerDialogVisible = false;
          this.$message.error(res.data.msg);
        }
      });
    },
    disasdGo() {
      this.disloagOve_6 = false
      this.$router.push({
        name: "msearchlist",
        params: { keyWords: this.searchb,keyType:this.searchType, sectionType: this.radioHustype },
      });
    },
    goSearch() {
      this.$router.push("/search");
    },
    goStar() {
      this.$store.commit("SET_HOMENAV", 8);
      this.$router.push({ name: "star" });
    },
    goStarSp() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "mstar" });
    },
    goHistory() {
      this.$store.commit("SET_HOMENAV", 7);
      this.$router.push({ name: "viewhis" });
    },
    goHistorym() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "mhistory" });
    },
    goHome() {
      this.$router.push({ name: "index" });
    },
    goHomeSp() {
      this.centerDialogVisible = false;
      this.$router.push({ name: "mobileindex" });
    },
    goSearchList() {
      if (!this.searchb) {
        this.$message.error("フリーワードを入力してください");
        return;
      }
      let params = {
        keyType: this.searchType,
        keyWords: this.searchb,
      };
      localStorage.setItem("searchValue", JSON.stringify(params));
      this.$apis.postProperty(params).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.params.showList) {
            if (res.data.data.result.length == 1) {
              let _ew = res.data.data.result[0];
              if (_ew.officeData.length == 1) {
                this.$router.push({
                  name: "mdetail",
                  query: {
                    propertyId: _ew.officeData[0].property_id,
                    purposeKbn: _ew.officeData[0].purpose_kbn,
                    id: _ew.officeData[0].id,
                    hus_type: _ew.officeData[0].hus_type,
                  },
                });
              } else {
                this.$router.push({
                  name: "msearchlist",
                  params: {
                    keyType: this.searchType,
                    keyWords: this.searchA,
                    sectionType: "",
                  },
                });
              }
            } else {
              this.$router.push({
                name: "msearchlist",
                params: {
                  keyType: this.searchType,
                  keyWords: this.searchA,
                  sectionType: "",
                },
              });
            }
          } else {
            if (res.data.data.propertyData) {
              let _data = res.data.data;
              localStorage.setItem("errorData", JSON.stringify(_data));
              this.$router.push("/medetail");
            } else {
              this.$router.push("/meseacher");
            }
          }
          this.centerDialogVisible = false;
        } else {
          this.centerDialogVisible = false;

          this.$message.error(res.data.msg);
        }
      });
    },
    goSearchNo() {
      if (this.search) {
        let params = {
          propertyNo: this.search,
        };
        localStorage.setItem("searchValue", JSON.stringify(params));
        this.$apis.postProperty(params).then((res) => {
          if (res.data.code == 200) {
            // if (res.data.data.params.showList) {
            //   this.$router.push({ name: 'msearchlist', params: { propertyNo: this.search, sectionType: "" } })
            // } else {
            //   this.$router.push('/detail')
            // }
            if (res.data.data.params.showList) {
              if (res.data.data.result.length == 1) {
                let _ew = res.data.data.result[0];
                if (_ew.officeData.length == 1) {
                  this.$router.push({
                    name: "mdetail",
                    query: {
                      propertyId: _ew.officeData[0].property_id,
                      purposeKbn: _ew.officeData[0].purpose_kbn,
                      id: _ew.officeData[0].id,
                      hus_type: _ew.officeData[0].hus_type,
                    },
                  });
                } else {
                  this.$router.push({
                    name: "msearchlist",
                    params: {
                      propertyNo: this.search,
                      sectionType: [_ew.officeData[0].purpose_kbn],
                    },
                  });
                }
              } else {
                let _ewq = res.data.data.result[0];
                this.$router.push({
                  name: "msearchlist",
                  params: {
                    propertyNo: this.search,
                    sectionType: [_ewq.officeData[0].purpose_kbn],
                  },
                });
              }
            } else {
              if (res.data.data.propertyData) {
                let _data = res.data.data;
                localStorage.setItem("errorData", JSON.stringify(_data));
                this.$router.push("/medetail");
              } else {
                this.$router.push("/meseacher");
              }
            }
          } else {
            this.$message.error(res.data.msg);
          }
          this.centerDialogVisible = false;
        });
      } else {
        this.centerDialogVisible = false;
        this.$message.error("物件番号を入力してください");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 95px;
  flex-shrink: 0;
  background: #051658;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.3);

  .box {
    width: 1023px;
    height: 95px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 353px;
      height: 62.871px;
      flex-shrink: 0;
      cursor: pointer;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .nav_item {
        // width: 95px;
        height: 62.871px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        cursor: pointer;

        .icon {
          width: 24px;
          height: 24px;
          margin-bottom: 18px;
        }

        .txt {
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: #fff;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;
        }

        .active {
          color: #ffd300 !important;
        }
      }
    }
  }

  .box_phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 95px;
    padding: 0 16px;
    position: relative;

    .logo {
      width: 168px;
      height: 30px;
    }

    .box_nav {
      display: flex;
      align-items: center;
      justify-content: center;

      .nav_item {
        width: 55px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-right: 14px;

        .item_icon {
          width: 24px;
          height: 24px;
        }

        .item_txt {
          font-size: 10px;
          color: #fff;
        }
      }
    }

    .nav_lists_box {
      position: fixed;
      left: 0;
      right: 0;
      top: 95px;
      bottom: 0;
      background: #051658;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      .nav_lists_item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        // padding-left: 31%;
        // height: 25px;

        .nav_list_icon {
          width: 24px;
          height: 24px;
          padding-bottom: 22px;
        }

        .nav_list_txt {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-family: "LINESeedJP_A_TTF_Bd";
          line-height: 24px;
          margin-left: 10px;
        }
      }

      .nav_list_search {
        width: 85%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 16px;
        cursor: pointer;
        background: #fff;
        color: #303030;
        // box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);

        .ter_item_icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        .ter_item_txt {
          font-size: 16px;
          font-style: normal;
          font-family: "LINESeedJP_A_TTF_Bd";
          height: 36px;

          .sm {
            font-size: 13px;
          }
        }

        .list_item_input {
          width: 90%;
          margin-top: 5px;
          position: relative;
          ::v-deep .el-input__inner {
            border: 1px solid #fff;
            height: 40px;
            line-height: 40px;
            padding-left: 25px;
            border-radius: 20px;
            background: #f5f3ee;
          }
          .tesw {
            ::v-deep .el-input__inner {
              border: 1px solid #fff;
              height: 40px;
              line-height: 40px;
              padding-left: 130px;
              border-radius: 20px;
              background: #f5f3ee;
            }
          }
          .search_sele {
            position: absolute;
            top: 0px;
            left: 5px;
            z-index: 9;
            .to_ads {
              width: 120px;

              ::v-deep .el-input__inner {
                background-color: #074697;
                color: #fff !important;
                border-radius: 48px;
                border: 1px solid #fff;
                color: #303030;
                height: 30px;
                line-height: 30px;
                text-align: right;
                font-family: "LINESeedJP_A_TTF_Bd";
                font-size: 13px;
                text-align: center;
              }

              ::v-deep .el-input__icon {
                line-height: 31px;
                color: #fff !important;
              }
            }

            ::v-deep .el-icon-arrow-up:before {
              content: "";
            }

            ::v-deep .el-input__suffix {
              height: 100%;
              right: 15px;
              top: 11px;
              transition: all 0.3s;
              pointer-events: none;
            }

            ::v-deep .el-select .el-input .el-select__caret {
              width: 8px;
              height: 8px;
              background-size: 100%;
              background-image: url("@/assets/icon/arrow_p.png");
              transform: rotate(0deg);
            }

            ::v-deep .el-select .el-input .el-select__caret.is-reverse {
              transform: rotate(180deg) !important;
            }
          }
        }
      }
    }
  }
  .disload_box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    z-index: 2023;
    background: rgb(0 0 0 / 69%);

    .boxsiz {
      width: 85%;
      height: 30%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 40%;
      left: 50%;
      z-index: 9999;

      .close_btn {
        position: absolute;
        top: -80px;
        right: 165px;
        font-size: 25px;
        color: #fff;
      }

      .content_header {
        width: 100%;
        height: 60px;
        border-radius: 16px 16px 0px 0px;
        text-align: center;
        font-size: 16px;
        font-family: "LINESeedJP_A_TTF_Bd";
        background: #fff;
      }

      .content_header_to {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-family: "LINESeedJP_A_TTF_Bd";
        background: #fff;
        margin-top: -1px;
        color: #000;
      }

      .content_header_tow {
        width: 100%;
        text-align: center;
        font-size: 14px;
        background: #fff;
        margin-top: -1px;
        padding-bottom: 25px;
      }

      .radio_box {
        height: auto;
        overflow: hidden;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: -1px;
        // padding: 20px 0 30px 130px;

        ::v-deep .el-checkbox__label {
          color: #303030;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
        }

        ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: #051658;
          border-color: #051658;
        }

        ::v-deep .el-checkbox__inner::after {
          border: 2px solid #fff;
          border-left: 0;
          border-top: 0;
        }

        ::v-deep .el-radio__label {
          color: #303030;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
        }

        ::v-deep .el-radio__input.is-checked .el-radio__inner,
        .el-radio__input.is-indeterminate .el-radio__inner {
          background-color: #051658;
          border-color: #051658;
        }

        .el-radio {
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
        }
        .el-radio-group{
          width: 165px;
          height: auto;
          margin: 20px auto 30px auto;
          padding-left: 70px;
        }
        .radiohome {
          width: 100%;
          margin-right: 0;
          height: 35px;
    display: flex;
    align-items: center;
          // margin-bottom: 15px;
        }
      }

      .content_footer {
        width: 100%;
        height: 52px;
        flex-shrink: 0;
        border-radius: 0px 0px 16px 16px;
        background: #051658;
        display: flex;
        justify-content: center;
        align-items: center;

        .content_footer_btn {
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 50px;
          background: #fff;
          color: #051658;
          font-size: 13px;
          font-style: normal;
          font-family: "LINESeedJP_A_TTF_Bd";
          line-height: normal;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
