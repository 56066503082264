<template>
  <div class="pagination">
    <div class="size_select" v-if="issize == 1">
      <div class="select_to" style="margin-right: 10px">
        <div class="txt">表示順</div>
        <el-select
          class="to_ads"
          v-model="page.sort"
          @change="handleCurrentChange3"
        >
          <el-option
            v-for="item in sortList"
            :key="item.id"
            :label="item.lable"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select_to">
        <el-select
          class="to_ad"
          v-model="page.limit"
          @change="handleCurrentChange2"
        >
          <el-option
            v-for="item in pageSizes"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <div class="txt">件表示</div>
      </div>
    </div>
    <div class="size_select_fo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageCare"
        :page-sizes="pageSizes"
        :page-size="page.limit"
        :pager-count="5"
        layout="prev, pager, next"
        :total="total"
        ref="pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "PagePostionSp",
  props: {
    issize: {
      type: Number,
    },
    total: {
      type: Number,
    },
    pageCare: {
      type: Number,
    },
    overPage: {
      type: Number,
    },
  },
  data() {
    return {
      pageSizes: [10, 20, 50, 100],
      sortList: [
        { id: 1, lable: "面積が大きい" },
        { id: 2, lable: "面積が小さい" },
        { id: 3, lable: "賃料が高い" },
        { id: 4, lable: "賃料が安い" },
        { id: 5, lable: "築年数が新しい" },
        { id: 6, lable: "築年数が古い" },
        { id: 7, lable: "更新が新しい" },
        { id: 8, lable: "更新が古い" },
      ],
      page: {
        page: 1,
        limit: 10,
        sort: 1,
      },
    };
  },
  methods: {
    // 每页条数变更
    handleSizeChange(val) {
      this.page.limit = val;
      this.page.page = 1;
      this.$emit("pageChange", this.page);
    },
    // 当前页码变更
    handleCurrentChange(val) {
      this.page.page = val;
      //  this.$refs.pagination.internalCurrentPage = 1;
      this.$emit("pageChange", this.page);
    },
    handleCurrentChange2(val) {
      this.page.limit = val;
      this.page.page = 1;
      //  this.$refs.pagination.internalCurrentPage = 1;
      this.$emit("pageChange", this.page);
    },
    handleCurrentChange3(val) {
      this.page.sort = val;
      this.page.page = 1;
      //  this.$refs.pagination.internalCurrentPage = 1;
      this.$emit("pageChange", this.page);
    },
  },
  watch: {
    total(newdata, olddata) {
      if (newdata != olddata) {
        this.$refs.pagination.internalCurrentPage = 1;
        // this.page.limit = 10;
        this.page.limit = this.overPage ? this.overPage : 10;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  margin: 20px 0;

  .size_select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;

    .select_to {
      display: flex;
      align-items: center;
      justify-content: center;

      .txt {
        width: 39px;
        color: #303030;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 15px;
      }

      .to_ad {
        width: 70px;
        margin-right: 20px;

        ::v-deep .el-input__inner {
          background-color: #f5f3ee;
          border-radius: 48px;
          border: 1px solid #fff;
          color: #303030;
          height: 32px;
          line-height: 32px;
          text-align: right;
          font-family: "LINESeedJP_A_TTF_Bd";
          font-size: 10px;
          text-align: center;
        }

        ::v-deep .el-input__icon {
          line-height: 31px;
        }
      }

      .to_ads {
        width: 112px;
        // margin-right: 30px;

        ::v-deep .el-input__inner {
          background-color: #f5f3ee;
          border-radius: 48px;
          border: 1px solid #fff;
          color: #303030;
          height: 40px;
          line-height: 40px;
          text-align: right;
          font-family: "LINESeedJP_A_TTF_Bd";
          font-size: 10px;
          text-align: center;
        }

        ::v-deep .el-input__icon {
          line-height: 31px;
        }
      }
    }
  }

  .size_select_fo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    .to_ad {
      width: 100px;
      margin-right: 15px;

      ::v-deep .el-input__inner {
        background-color: #f5f3ee;
        border-radius: 48px;
        border: 1px solid #fff;
        color: #303030;
        height: 40px;
        line-height: 40px;
        text-align: right;
        font-family: "LINESeedJP_A_TTF_Bd";
        font-size: 13px;
        text-align: center;
      }

      ::v-deep .el-input__icon {
        line-height: 31px;
      }
    }

    .txt {
      color: #303030;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  ::v-deep .el-pager li.active {
    color: #fff;
    background: #051658;
    cursor: default;
    border-radius: 50%;
  }

  ::v-deep .el-pager li {
    font-size: 13px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 5px;
    background: none;
  }

  ::v-deep .el-pagination button {
    // margin-top: 5px;
    // width: 24px;
    // height: 24px;
  }

  ::v-deep .el-pagination button:disabled {
    // background: none;
  }

  ::v-deep .el-pagination .btn-next,
  .el-pagination .btn-prev {
    background: none;
  }

  ::v-deep .el-icon-arrow-up:before {
    content: "";
  }

  ::v-deep .el-input__suffix {
    height: 100%;
    right: 15px;
    top: 12px;
    transition: all 0.3s;
    pointer-events: none;
  }

  ::v-deep .el-select .el-input .el-select__caret {
    width: 5px;
    height: 5px;
    background-size: 100%;
    background-image: url("@/assets/icon/arrow.png");
    transform: rotate(0deg);
  }

  ::v-deep .el-select .el-input .el-select__caret.is-reverse {
    transform: rotate(180deg) !important;
  }
  ::v-deep .el-pagination button i {
    display: none;
  }
  ::v-deep .el-pagination button:disabled {
    // background-image: url("@/assets/img/arrow_back.png");
    // background: #999;
    // background-size: 100% 100%;
  }
  ::v-deep .el-pagination .btn-prev {
    // background: ;
    background: url("@/assets/img/arrow_back.png");
    background-size: 100% 100%;
  }

  ::v-deep .el-pagination .btn-next {
    background: url("@/assets/img/arrow_forward.png");
    background-size: 100% 100%;
  }
}
</style>
