/*
 * @Author:
 * @Date:
 * @Last Modified by:
 * @Last Modified time:
 */
const note = {
  state: {
    catalogue: [],
    scrollTop: 0,
    catalogueHeight: 0,
    homeNav: 1,
    homeNavSp: 1,
    userAgent: 1,
  },

  mutations: {
    SET_CATALOGUE: (state, catalogue) => {
      state.catalogue = catalogue;
    },
    SET_SCROLLTOP: (state, scrollTop) => {
      state.scrollTop = scrollTop;
    },
    SET_CATALOGUEHEIGHT: (state, catalogueHeight) => {
      state.catalogueHeight = catalogueHeight;
    },
    SET_HOMENAV: (state, homeNav) => {
      state.homeNav = homeNav;
    },
    SET_HOMENAV_SP: (state, homeNavSp) => {
      state.homeNavSp = homeNavSp;
    },
    SET_USERAGENT: (state, userAgent) => {
      state.userAgent = userAgent;
    },
  },
  actions: {},
};
export default note;
