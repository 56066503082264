export const menu = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error/404.vue"),
  },
  {
    path: "/comment",
    name: "comment",
    component: () => import("@/views/text/comment.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () => import("@/views/text/service.vue"),
  },
  {
    path: "/monster",
    name: "monster",
    component: () => import("@/views/text/monster.vue"),
  },
  {
    path: "/question",
    name: "question",
    component: () => import("@/views/text/question.vue"),
  },
  {
    path: "/inApply",
    name: "inApply",
    component: () => import("@/views/form/inApply.vue"),
  },
  {
    path: "/request",
    name: "request",
    component: () => import("@/views/form/request.vue"),
  },
  {
    path: "/consult",
    name: "consult",
    component: () => import("@/views/form/consult.vue"),
  },
  {
    path: "/send",
    name: "send",
    component: () => import("@/views/form/send.vue"),
  },
  {
    path: "/move",
    name: "move",
    component: () => import("@/views/text/move.vue"),
  },
  {
    path: "/perinfo",
    name: "perinfo",
    component: () => import("@/views/text/perinfo.vue"),
  },
  {
    path: "/link",
    name: "link",
    component: () => import("@/views/text/link.vue"),
  },
  {
    path: "/confirm",
    name: "confirm",
    component: () => import("@/views/form/confirm.vue"),
  },
  {
    path: "/office",
    name: "office",
    component: () => import("@/views/card/office.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/text/terms.vue"),
  },
  {
    path: "/counts",
    name: "counts",
    component: () => import("@/views/static/counts.vue"),
  },
  {
    path: "/edetail",
    name: "edetail",
    component: () => import("@/views/detail/eindex.vue"),
  },
  {
    path: "/esearch",
    name: "esearch",
    component: () => import("@/views/search/error.vue"),
  },

  
]
