import Vue from "vue";
import VueRouter from "vue-router";
import { route } from "./modules/routes";
import { mobile } from "./modules/mobile";
import { systemAgent } from "../utils/index";

const originalPush = VueRouter.prototype.push;

const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const userRoutes = systemAgent() == 1 ? route : mobile;
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  // base: process.env.VUE_APP_BASE_API,
  routes: userRoutes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next("/404");
  } else {
    next();
  }
});
export default router;
