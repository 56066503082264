export const mobile = [
  {
    path: "/",
    name: "mobileindex",
    component: () => import("@/views/home/mobileIndex.vue"),
  },
  {
    path: "/mmove",
    name: "mmove",
    component: () => import("@/views/mobile/mmove.vue"),
  },
  {
    path: "/mterms",
    name: "mterms",
    component: () => import("@/views/mobile/mterms.vue"),
  },
  {
    path: "/mmonster",
    name: "mmonster",
    component: () => import("@/views/mobile/mmonster.vue"),
  },
  {
    path: "/mconsult",
    name: "mconsult",
    component: () => import("@/views/mobile/mconsult.vue"),
  },
  {
    path: "/minApply",
    name: "minApply",
    component: () => import("@/views/mobile/minApply.vue"),
  },
  {
    path: "/mconfirm",
    name: "mconfirm",
    component: () => import("@/views/mobile/mconfirm.vue"),
  },
  {
    path: "/mquestion",
    name: "mquestion",
    component: () => import("@/views/mobile/mquestion.vue"),
  },
  {
    path: "/mperinfo",
    name: "mperinfo",
    component: () => import("@/views/mobile/mperinfo.vue"),
  },
  {
    path: "/mservice",
    name: "mservice",
    component: () => import("@/views/mobile/mservice.vue"),
  },
  {
    path: "/mlink",
    name: "mlink",
    component: () => import("@/views/mobile/mlink.vue"),
  },
  {
    path: "/mcomment",
    name: "mcomment",
    component: () => import("@/views/mobile/mcomment.vue"),
  },
  {
    path: "/msend",
    name: "msend",
    component: () => import("@/views/mobile/msend.vue"),
  },
  {
    path: "/mstar",
    name: "mstar",
    component: () => import("@/views/spage/start.vue"),
  },
  {
    path: "/mhistory",
    name: "mhistory",
    component: () => import("@/views/spage/history.vue"),
  },
  {
    path: "/msearchlist",
    name: "msearchlist",
    component: () => import("@/views/spage/search.vue"),
  },
  {
    path: "/medetail",
    name: "medetail",
    component: () => import("@/views/spage/edetail.vue"),
  },
  {
    path: "/meseacher",
    name: "meseacher",
    component: () => import("@/views/spage/eseacher.vue"),
  },
  {
    path: "/mcounts",
    name: "mcounts",
    component: () => import("@/views/mobile/mcounts.vue"),
  },
  {
    path: "/mrequest",
    name: "mrequest",
    component: () => import("@/views/mobile/mrequest.vue"),
  },
  {
    path: "/moffice",
    name: "moffice",
    component: () => import("@/views/mobile/moffice.vue"),
  },
  {
    path: "/mdetail",
    name: "mdetail",
    component: () => import("@/views/spage/detail.vue"),
  },
  {
    path: "/mone",
    name: "mone",
    component: () => import("@/views/snavpage/one.vue"),
  },
  {
    path: "/mtwo",
    name: "mtwo",
    component: () => import("@/views/snavpage/two.vue"),
  },
  {
    path: "/mthree",
    name: "mthree",
    component: () => import("@/views/snavpage/three.vue"),
  },
  {
    path: "/mfour",
    name: "mfour",
    component: () => import("@/views/snavpage/four.vue"),
  },
  {
    path: "/mfive",
    name: "mfive",
    component: () => import("@/views/snavpage/five.vue"),
  },
  {
    path: "/msix",
    name: "msix",
    component: () => import("@/views/snavpage/six.vue"),
  },
  {
    path: "/mseven",
    name: "mseven",
    component: () => import("@/views/snavpage/seven.vue"),
  },
  {
    path: "/msendend",
    name: "msendend",
    component: () => import("@/views/snavpage/sendend.vue"),
  },
  {
    path: "/msendlog",
    name: "msendlog",
    component: () => import("@/views/snavpage/sendlog.vue"),
  },
];
