<template>
  <div id="app">
    <el-container>
      <el-header height="95px">
        <Header />
      </el-header>
      <el-main>
        <router-view />
        <transition name="slide-fade">
          <div class="pu_up" @click="goTop" v-show="toTopVisible&&$store.state.note.userAgent == 1">
            <img src="@/assets/new/pagetop.png">
          </div>
        </transition>
      </el-main>
      <el-footer :height="$store.state.note.userAgent == 1 ? '292px' : '420px'">
        <Footer />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Header from "@/views/components/header/index.vue";
import Footer from "@/views/components/footer/index.vue";
// import { resize } from "@/utils/rem";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isCollapse: false,
      isShrink: false,
      toTopVisible: false,
      list: [],
    };
  },
  created() {
    // window.onresize = function () {
    //   resize();
    // };
    // resize();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 检查是否滚动到底部
      this.toTopVisible = window.scrollY !== 0;
    },
    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: "LINESeedJP_A_TTF_Rg";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .el-container {
    overflow: hidden;

    .el-header {
      background-color: #051658;
      color: #fff;
      text-align: center;
      line-height: 60px;
      padding: 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    }

    .el-footer {
      background-color: #303030;
      color: #fff;
      text-align: center;
      line-height: 60px;
      padding: 0px;
    }

    .el-main {
      position: relative;
      background: #f5f3ee;
      width: 100%;
      height: auto;
      padding: 95px 0 0 0;
      overflow: auto;
    }
  }
  .pu_up {
    position: fixed;
    width: 56px;
    height: 56px;
    bottom: 100px;
    right: 100px;
    color: #051658;
    font-size: 40px;
    z-index: 999;
    cursor: pointer;
  }
  .slide-fade-enter-active {
    transition: all 0.5s ease;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(0px);
    opacity: 0;
  }
}
</style>
