import { menu } from './menu'
const routes = [
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search/index.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/detail/index.vue"),
  },
  {
    path: "/star",
    name: "star",
    component: () => import("@/views/star/index.vue"),
  },
  {
    path: "/viewhis",
    name: "viewhis",
    component: () => import("@/views/star/viewhis.vue"),
  },
];
export const route = [...routes,...menu]
